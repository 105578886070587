<template>
  <div>
    <gl-title title="提款管理"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="onClickAction('updateVisible')"> 提现设置 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="userName" label="用户昵称"></el-table-column>
        <el-table-column prop="idname" label="提款人名称"></el-table-column>
        <el-table-column prop="accountNumber" label="提款账号"></el-table-column>
        <el-table-column prop="accountType" label="提款方式">
          <template slot-scope="{row}">
            <span v-if="row.accountType == 1"> 微信 </span>
            <span v-if="row.accountType == 2"> 支付宝 </span>
            <span v-else> 银行卡 </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="审批状态">
          <template slot-scope="{row}">
            <span v-if="row.approval == 1"> 已通过 </span>
            <span v-if="row.status == 2"> 不通过 </span>
            <span v-else> 待审批 </span>
          </template>
        </el-table-column>
        <el-table-column prop="paymentType" label="提款状态">
          <template slot-scope="{row}">
            <span v-if="row.paymentType == 1"> 已打款 </span>
            <span v-else> 未打卡 </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px" fixed="right">
          <template slot-scope="{row}">
            <!-- v-if="row.approval == 3" -->
            <el-button type="text" @click="onClickAction('approveVisible', row)"> 审核 </el-button>
            <el-button type="text" @click="onClickAction('remitVisible', row)"> 打款 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <extracSet :itemId="itemId" :visible.sync="updateVisible"/>
    <approve :itemId="itemId" :visible.sync="approveVisible" :status="itemData.approval" @reload="mReload"/>
    <remit :itemData="itemData" :visible.sync="remitVisible"/>
  </div>
</template>

<script>
import {extractList} from '@/api/backendall/finance'
import ListMixin from '@/mixins/list.mixin.js'
import extracSet from './extractSet.vue'
import approve from './approve.vue'
import remit from './remit.vue'
export default {
  components: {extracSet,approve,remit},
  mixins: [ListMixin],
  data(){
    return{
      updateVisible: false,
      approveVisible: false,
      remitVisible: false,
      itemId: '',
      // status: 0,
      itemData: {},
    }
  },
  mounted(){
    this.mGetListFun = extractList
    this.mGetList()
  },
  methods: {
    onClickAction(name, item, status){
      this.itemId = item?.balanceExtractId || ''
      this.itemData = {...item}
      this[name] = true
    }
  },
}
</script>

<style>

</style>