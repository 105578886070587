<template>
  <el-dialog 
    title="提现审核"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="approval" label="审批状态">
          <!-- <el-input v-model="formData.approval"></el-input> -->
          <div> {{statusEnum[Number(status)]}} </div>
        </el-form-item>
        <el-form-item prop="remark" label="审批备注" verify>
          <el-input type="textarea" v-model="formData.remark" :maxlength="500" :rows="5"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex style="margin-top: 50px;">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {approve} from '@/api/backendall/finance'
export default {
  name: 'audit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
    status: {
      type: [Number,String],
      default: 0,
    },
  },
  data(){
    return {
      formData: {},
      loading: false,
      statusEnum: {
        '1': '已通过',
        '2': '不通过',
        '3': '待审核'
      }
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        this.formData.balanceExtractId = this.itemId
        const params = {
          balanceExtractId: this.itemId,
          approval: this.status,
          remark: this.formData.remark,
        }
        const res = await approve(params)
        this.loading = false
        this.handleClose()
        this.$message.success('操作成功')
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style>

</style>