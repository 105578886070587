var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('gl-title',{attrs:{"title":"提款管理"}}),_c('div',{staticStyle:{"margin":"18px"}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onClickAction('updateVisible')}}},[_vm._v(" 提现设置 ")])],1),_c('gl-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.mLoading),expression:"mLoading"}]},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.mTableData}},[_c('el-table-column',{attrs:{"type":"index","index":_vm.mIndexMethod,"label":"序号","align":"center","width":"44"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"用户昵称"}}),_c('el-table-column',{attrs:{"prop":"idname","label":"提款人名称"}}),_c('el-table-column',{attrs:{"prop":"accountNumber","label":"提款账号"}}),_c('el-table-column',{attrs:{"prop":"accountType","label":"提款方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.accountType == 1)?_c('span',[_vm._v(" 微信 ")]):_vm._e(),(row.accountType == 2)?_c('span',[_vm._v(" 支付宝 ")]):_c('span',[_vm._v(" 银行卡 ")])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"审批状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.approval == 1)?_c('span',[_vm._v(" 已通过 ")]):_vm._e(),(row.status == 2)?_c('span',[_vm._v(" 不通过 ")]):_c('span',[_vm._v(" 待审批 ")])]}}])}),_c('el-table-column',{attrs:{"prop":"paymentType","label":"提款状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.paymentType == 1)?_c('span',[_vm._v(" 已打款 ")]):_c('span',[_vm._v(" 未打卡 ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"130px","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.onClickAction('approveVisible', row)}}},[_vm._v(" 审核 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.onClickAction('remitVisible', row)}}},[_vm._v(" 打款 ")])]}}])})],1),_c('gl-pagination',{attrs:{"total":_vm.mTotal,"current-page":_vm.mPageNum},on:{"currentChange":_vm.mHandleCurrentChange,"sizeChange":_vm.mHandleSizeChange}})],1),_c('extracSet',{attrs:{"itemId":_vm.itemId,"visible":_vm.updateVisible},on:{"update:visible":function($event){_vm.updateVisible=$event}}}),_c('approve',{attrs:{"itemId":_vm.itemId,"visible":_vm.approveVisible,"status":_vm.itemData.approval},on:{"update:visible":function($event){_vm.approveVisible=$event},"reload":_vm.mReload}}),_c('remit',{attrs:{"itemData":_vm.itemData,"visible":_vm.remitVisible},on:{"update:visible":function($event){_vm.remitVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }