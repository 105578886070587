<template>
  <el-dialog 
    title="打款"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="itemData" ref="form" label-width="150px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="userName" label="用户昵称">
          <div> {{itemData.userName}} </div>
        </el-form-item>
        <el-form-item prop="idname" label="提款人名称">
          <div> {{itemData.idname}} </div>
        </el-form-item>
        <el-form-item prop="accountNumber" label="提款账号">
          <div> {{itemData.accountNumber}} </div>
        </el-form-item>
        <el-form-item prop="accountType" label="提款方式">
          <div> {{accountTypeObject[itemData.accountType]}} </div>
        </el-form-item>
        <el-form-item prop="approval" label="审批状态">
          <div> {{statusEnum[Number(itemData.approval)]}} </div>
        </el-form-item>
        <el-form-item prop="remark" label="审批备注">
          <div>{{statusEnum[Number(itemData.remark)]}}</div>
        </el-form-item>
        <el-form-item prop="extractStart" label="提现申请时间开始">
          <div> {{itemData.extractStart}} </div>
        </el-form-item>
        <el-form-item prop="extractEnd" label="提现申请时间结束">
          <div> {{itemData.extractEnd}} </div>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex style="margin-top: 50px;">
      <el-button type="primary" @click="onSubmit">确认打款</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {payment} from '@/api/backendall/finance'
export default {
  name: 'audit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: () => {}
    },
  },
  data(){
    return {
      formData: {},
      loading: false,
      statusEnum: {
        '1': '已通过',
        '2': '不通过',
        '3': '待审核'
      },
      accountTypeObject: {
        '1': '微信',
        '2': '支付宝',
        '3': '银行卡'
      },
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        const params = {
          balanceExtractId: this.itemData.balanceExtractId
        }
        const res = await payment(params)
        if(res.code == 200 && res.data == null){
          this.$message.success('操作成功')
        }else{
          this.$message.error(res.msg)
        }
        this.loading = false
        this.$emit('reload')
        this.handleClose()
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style>

</style>