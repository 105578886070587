<template>
  <el-dialog 
    title="提现设置"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="150px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="minAmount" label="单次提现最低金额" verify>
          <el-input v-model.number="formData.minAmount" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item prop="maxAmount" label="单次提现最高金额" verify>
          <el-input v-model.number="formData.maxAmount" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item prop="serviceCharge" label="提现手续费%" verify>
          <el-input v-model.number="formData.serviceCharge" :maxlength="3"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex style="margin-top: 50px;">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getExtractSet,updateExtractSet} from '@/api/backendall/finance'
export default {
  name: 'audit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      formData: {
        extractId: '',
        minAmount: 0,
        maxAmount: 0,
        serviceCharge: 0,
      },
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.getExtractSet()
      }
    },
  },
  methods: {
    async getExtractSet(){
      this.loading = true
      const res = await getExtractSet()
      this.formData = res.data
      this.loading = false
    },
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        const res = await updateExtractSet(this.formData)
        this.loading = false
        this.handleClose()
        this.$message.success('操作成功')
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style>

</style>